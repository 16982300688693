import axios from 'axios'
import { getToken } from '@/utils/setToken.js'
// import { Message } from 'element-ui'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
    baseURL: '/api', //baseURL会自动加在请求地址上
    timeout: 20000
})
// 添加请求拦截器
service.interceptors.request.use((config) => {
    // 在请求之前做些什么（获取并设置token）
    // config.headers['token'] = getToken('token')
    return config
}, (error) => {
    return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
    // 在响应数据做些什么（获取并设置token）
    /*
    let { code, msg } = response.data
    if (code !== 200) {
        Message({ message: msg || 'error', type: 'warning' })
    }
    */
    return response
}, (error) => {
    return Promise.reject(error)
})
export default service