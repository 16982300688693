import router from "./router";
import Cookies from "js-cookie";

const whiteList = ['/login', '/404']

router.beforeEach((to, from, next) => {
    const uuid = Cookies.get('uuid')
    if (uuid) {
        if (to.path === '/login') {
            next({path: '/index'})
        } else {
            next()
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next({path: '/login'})
        }
    }
})