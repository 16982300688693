import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
export const constantRoutes = [
    {
        path: '/login',
        component: () => import('@/views/login')
    },
    {
        path: '/',
        component: () => import('@/views/index')
    },
    {
        path: '/index',
        component: () => import('@/views/index')
    },
    {
        path: '/404',
        component: () => import('@/views/404')
    },
    {
        path: '*',
        redirect: '/404'
    }
]

export default new Router({
    mode: 'history',
    scrollBehavior: () => ({
        y: 0
    }),
    routes: constantRoutes
})